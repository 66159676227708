import Util from "@/utils/utils";
import {
  getList,
  getExport,
  getYxList,
  getStatusList,
  delLine,
  shLc,
  terminalActivity,
  getActivityAttributeOptions
} from "./api";
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import {
  env
} from "@/common/constant";
export default {
  name: "terminalInspectionRecord",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/agentVerificationActivities",
          name: "代理商市场活动",
          title: "代理商市场活动"
        }
      ],
      visibleshop: false,
      hideOrShowSize: true,
      columns: [
        {
          label: "营销中心",
          prop: "marketCenterName",
          width: 200,
        },
        {
          label: "办事处",
          prop: "orgName",
          width: 200,
        },
        {
          label: "申请活动时间",
          prop: "applyMonth",
          width: 200,
        },
        {
          label: "活动开始时间",
          prop: "applyStartTime",
          width: 200,
        },
        {
          label: "活动结束时间",
          prop: "applyEndTime",
          width: 200,
        },
        {
          label: "活动承接代理(运营)商",
          prop: "custInfoName",
          width: 200,
        },
        {
          label: "活动主题",
          prop: "activityTheme",
          width: 200,
        },
        {
          label: "活动编码",
          prop: "activityCode",
          width: 200,
        },
        {
          label: "活动地点",
          prop: "activityPlace",
          width: 200,
        },
        {
          label: "参与分销商",
          prop: "fxCustName",
          width: 200,
        },
        {
          label: "项目目标(万元)",
          prop: "activityTarget",
          width: 200,
        },
        {
          label: "活动实际销售额(万元)",
          prop: "activityReal",
          width: 200,
        },
        {
          label: "各项投入合计",
          prop: "totalMoney",
          width: 200,
        },
        {
          label: "可兑现金额(元)",
          prop: "cashableMoney",
          width: 200,
        },
        {
          label: "股份公司分摊金额(元)",
          prop: "stockMoney",
          width: 200,
        },
        {
          label: "空调公司分摊金额(元)",
          prop: "airConditionerMoney",
          width: 200,
        },
        {
          label: "冰箱公司分摊金额(元)",
          prop: "refrigeratorMoney",
          width: 200,
        },
        {
          label: "待制作广告公司",
          prop: "adCompany",
          width: 200,
        },
        {
          label: "活动属性",
          prop: "activityAttributeText",
          width: 200,
        },
        {
          label: "审批状态",
          prop: "checkStatus",
          width: 200,
        },
        {
          label: "状态",
          prop: "status",
          width: 200,
        },
        {
          label: "驳回原因",
          prop: "reason",
          width: 200,
        },
        {
          label: "提报人",
          prop: "creator",
          width: 200,
        },
        {
          label: "提报时间",
          prop: "createdDate",
          width: 200,
        },
        // 新增字段
        {
          label: "核销时间",
          prop: "writeDate",
          width: 200,
        },
        {
          label: "操作",
          slot: "btnClick",
          ellipsis: true,
          fixed: 'right',
          width: 200,
        },
      ],
      tableData: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {
        marketCenter: "", // 营销中心编码
        office: "", // 办事处
        agent: "", // 代理商名称
        distributor: "", // 分销商名称
        timeStart: "", // 申请活动开始时间
        timeEnd:"", // 申请活动结束时间
        applyStartTime:"", // 活动开始时间
        applyEndTime:"", // 活动结束时间
        theme: "", // 活动主题
        place: "", // 活动地点
        pageNo: 1,
        pageSize: 10,
        adCompany: '',//待制作广告公司
        checkStatus: '', // 状态
        creator: '', // 提报人
        status: '',// 状态
        activityCode: '',
        activityAttribute: "", //活动属性
      },
      searchNumber: 0,
      hideshow: false,
      isLoading: false,
      onluyOneMessage: false,
      imgURL: [],
      tableLoading: false,
      marketCenterList: [], // 营销中心数据
      marketCenterName: '', //营销中心
      statusList: [], // 审批状态数据
      statusName: '', //审批状态
      statusName1: '', // 状态数据
      statusList1: ["申请中", "已申请", "核销中", "已核销", "作废","系统自动作废"],
      selectedRowKey: [],
      selectedRowKeys: '',
      rowDta: '',
      mesageInfoTips: '确定删除该条数据？',
      selsectRow: {},
      aaaaa: false,
      fileList: [],
      fileIds: [],
      BUTTONloading:false,
      Canbutton: true,
      zfVisible:false,
      rowStatus:'', // 打开弹窗的状态复制
      activityAttributeText: '',
      activityAttributeOptions: []
    };
  },
  watch:{
    fileIds: {
      immediate: true,
      handler(newValue,oldValue) {
        if(this.fileIds.length>0) {
          this.Canbutton = false
        } else  {
          this.Canbutton = true
        }
      }
    }
  },
  mounted() {
    this.tablelist();
    // 获取营销模式数据u
    this.getYYXT()
    this.getStatus()
    this.getActivityAttributeOptions()
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    // 跳转查看流
    gotoUrl(row,type) {
      // 关联任务CIS-15843
      if(type ==  'HX' && !row.writeProcessId ){
        this.$message.warning('暂未发起申请/核销')
        return
      }
      // 查看流程传流程id  查看核销流程 传核销id
      let id = type == 'LC' ? row.processInstId : row.writeProcessId

      if (env == "dev" || env == "test" || env == "uat") {
        // 测试
        window.open('http://xtwdev.clouddev.hisense.com/auth-api/displayFlow?param='+id, "_blank");
      } else {
        // 正式
        window.open('https://xtw.hisense.com/auth-api/displayFlow?param='+id, "_blank");
      }
    },
    fun_date1() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm =  (new Date().getMonth()+ 1) < 10 ? "0" + (new Date().getMonth()+ 1) : new Date().getMonth()+ 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return yy + "-" + mm+ "-" +dd;

    },
    // 作废打开弹窗
    //前台功能增加“作废”按钮，点击按钮后先进行校验，流程状态是否为“申请中”、“核销中”如状态不对则弹窗提示：只有申请中和核销中的活动才可以作废，请检查活动状态。
    // 如状态校验通过，弹窗提示：您的操作将会作废该活动并且只能重新发起活动申请，请问您确认吗？点击确认按钮则继续后台操作。
    delzf(row) {
      // this.mesageInfoTips = ''
      this.rowDta = ''
      this.zfVisible = true
      this.rowDta = row.id
      this.rowStatus= row.status
      // 状态
      if(this.rowStatus == '申请中' || this.rowStatus == '核销中') {
        this.mesageInfoTips = '您的操作将会作废该活动并且只能重新发起活动申请，请问您确认吗？'
      } else {
        this.mesageInfoTips = '只有申请中和核销中的活动才可以作废，请检查活动状态'
      }
    },
    // 作废按钮确定弹窗  根据点击行上状态判断 如果是符合条件 就掉接口 否则就关闭弹窗
    delectzf() {
      if(this.rowStatus == '申请中' || this.rowStatus == '核销中') {
        // 调用作废接口（和乐乐对接）
        let data = {
          id: this.rowDta
        }
        terminalActivity(data).then(res => {
          if (res.data.code == 0) {
            this.$message.success(res.data.msg)
            this.tablelist()
          } else {
            this.$message.warning(res.data.msg)
          }
        })
      } else {
        this.rowDta = ''
        this.zfVisible = false
        this.rowDta = ''
        this.rowStatus= ''
      }

    },
    beforeUpload(file) {
      const size = file.size / 1024 / 1024 < 30;
      if (!size) {
        this.$message.warning("文件大小不能超过30M！");
        return
      }
      // this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file", file);
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      // 发送请求
      this.Canbutton = true
      const BASE_API = publicPath;
      axios.post(BASE_API + '/custAgentActivity/uploadFile.nd', formData, config).then(res => {
        if (res.data.message == '上传成功') {
          this.fileIds.push({
            id: res.data.businessId
          })
          this.fileList = [...this.fileList, file];
        } else {
          this.$message.warning('上传失败', res.data.message)
        }
        this.Canbutton = false
      })
      return false;
    },
    handleChange({ fileList }) {
      // this.fileList = fileList;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1)
    },
    handleOk() {
      this.BUTTONloading = true
      let arrIds = []
      this.fileIds.forEach(item => {
        arrIds.push(
          {
            id: item.id
          }
        )
      })
      let data = {
        id: this.selsectRow.id,
        voucherAttachs: arrIds,
        processInstId: this.selsectRow.writeProcessId,
        activityInstId: this.selsectRow.writeActivityId
      }
      shLc(data).then(res => {
        if (res.data.code == 0) {
          this.aaaaa = false
          this.fileList = []
          this.fileIds = []
          this.selsectRow = {}
          this.$message.success(res.data.msg,3)
          this.tablelist()
        } else {
          this.$message.warning(res.data.msg,3)
          this.aaaaa = false
          this.fileList = []
          this.fileIds = []
          this.selsectRow = {}
          this.tablelist()
         
        }
      })
      this.BUTTONloading = false
      // this.fileIds
      // this.selsectRow 
    },
    // 关闭弹窗
    cancle() {
      this.fileList = [],
        this.fileIds = [],
        this.aaaaa = false
    },
    // 打开上传图片的弹窗
    upload(row) {
      this.selsectRow = row
      this.aaaaa = true
    },
    // 删除
    del(row) {
      this.rowDta = ''
      this.visibleshop = true
      this.rowDta = row.id
    },
    delect() {
      let data = {
        id: this.rowDta
      }
      delLine(data).then(res => {
        if (res.data.code == 0) {
          this.tablelist()
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    // 选择项更改
    onChange(selRows) {
      this.selectedRowKey = selRows
    },
    // 跳转页面() 
    add(i, row) {
      if (i == 'hexiao') {
        //  修改核销提示  如果是已申请 或者审批状态为驳回或者核销中 并且当前时间大于活动时间 直接跳转页面  否则给出提示
        if (((row.status == '已申请' || (row.checkStatus == '驳回' && row.status == '核销中')) && row.isShowHx)) {
          let routeUrl = this.$router.resolve({
            path: "/agentVerificationActivities/writeOff",
            query: {
              type: i,
              id: row.id
            },
          });
          window.open(routeUrl.href, "_blank");
        } else {
          this.$message.warning('只有已申请或者核销中并且当前时间大于等于活动结束时间的数据可以核销！', 5)
          return
        }
      }
      if (i == 'edit') {
        if ((row.checkStatus == '草稿' || row.checkStatus == '驳回' || row.checkStatus == "作废")) {
          if (row.status == "申请中") {
            let routeUrl = this.$router.resolve({
              path: "/agentVerificationActivities/addOredit",
              query: {
                type: i,
                id: row.id
              },
            });
            window.open(routeUrl.href, "_blank");
          } else {
            this.$message.warning('只有申请中的数据可以编辑！')
          }
        } else {
          this.$message.warning('只有草稿、驳回和作废的数据可以编辑！')
        }


      }
      if (i == 'add') {
        let routeUrl = this.$router.resolve({
          path: "/agentVerificationActivities/addOredit",
          query: {
            type: i,
            // id:row.id
          },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    // 查看
    openView() {
      // 判断是都选中数据
      if (this.selectedRowKey.length <= 0) {
        this.$message.warning('请选择一条数据进行查看')
      } else if (this.selectedRowKey.length > 1) {
        this.$message.warning('只能选择一条数据进行查看')
      } else {
        let routeUrl = this.$router.resolve({
          path: "/agentVerificationActivities/activityView",
          query: { id: this.selectedRowKey[0].id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.ssearchList.timeStart = defaultTime?defaultTime:'';
    },
    // 时间更改
    handleTimeChange2(e, defaultTime) {
      this.ssearchList.applyStartTime = defaultTime?defaultTime:'';
    },
    // 时间更改
    handleTimeChange3(e, defaultTime) {
      this.ssearchList.applyEndTime = defaultTime?defaultTime:'';
    },
    // 获取营销系统数据
    getYYXT() {
      let data = {}
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data
      })
    },
    // 审批状态数据
    getStatus() {
      let data = {}
      getStatusList(data).then(res => {
        this.statusList = res.data.data
      })
    },
    // 更改
    handleChangeshopList(value, e) {
      this.marketCenterName = value?value:'';
      this.ssearchList.marketCenter = e?e.key:'';
    },
    // 更改
    handleChangestatuspList(value, e) {
      this.statusName = value?value:'';
      this.ssearchList.checkStatus =  e?e.key:'';
    },
    // 更改
    handleChangestatuspList1(value, e) {
      this.statusName1 = value?value:'';
      this.ssearchList.status = e?e.key:'';
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fun_date(num) {
      var date1 = new Date();
      var yuefen =
        date1.getMonth() + 1 < 10
          ? "0" + Number(date1.getMonth() + 1)
          : date1.getMonth();
      var ri =
        date1.getDate() + 1 < 10 ? "0" + date1.getDate() : date1.getDate();
      var time1 = date1.getFullYear() + "-" + yuefen + "-" + ri;
      this.ssearchList.checkEnd = time1;

      var date2 = new Date(date1);
      date2.setDate(date1.getDate() + num);
      var yu =
        date2.getMonth() + 1 < 10
          ? "0" + Number(date2.getMonth() + 1)
          : date2.getMonth();
      var riq =
        date2.getDate() + 1 < 10 ? "0" + date2.getDate() : date2.getDate();
      var time2 = date2.getFullYear() + "-" + yu + "-" + riq;
      this.ssearchList.checkStart = time2;
    },
    exportClick() {
      let data = this.ssearchList;
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "代理商市场活动列表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    loadMore() {
      this.hideshow = !this.hideshow;
    },
    pageSearch(pager) {
      this.ssearchList.pageNo = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.pager.pageNo = pager.pageNo
      this.pager.pageSize = pager.pageSize
      this.tablelist();
    },
    tablelist() {
      let data = this.ssearchList;
      this.tableLoading = true
      getList(data).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.content;
          this.tableData.forEach(item=>{
            // 默认都展示核销
            item.isShowHx = true
            // 如果有活动结束时间，如果有结束时间 则判断  当前时间大于核销结束时间 才会出现核销按钮
            if(item.applyEndTime) {
              // 获取当前年月日
              let nowTime = this.fun_date1()
              if( new Date(nowTime).getTime() >= new Date(item.applyEndTime).getTime()) {

                item.isShowHx = true
              } else {
                item.isShowHx = false
              }
            }

          })
          if (res.data.data.content) {
            this.searchNumber = res.data.data.totalRows;
            this.pager.count = res.data.data.totalRows;
          }
          this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }

      });
    },


    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        // path: "/center/terminalInspectionRecord/terminalInspectiondetail",
        query: { id: row.id },
      });
      window.open(routeUrl.href, "_blank");
    },

    hideOrShow(data) {
      this.hideOrShowSize = data;
    },
    // 重置
    reset() {
      this.ssearchList = {
        marketCenter: "", // 营销中心编码
        office: "", // 办事处
        agent: "", // 代理商名称
        distributor: "", // 分销商名称
        timeStart: "", // 申请活动开始时间
        timeEnd:"", // 申请活动结束时间
        applyStartTime:"", // 活动开始时间
        applyEndTime:"", // 活动结束时间
        theme: "", // 活动主题
        place: "", // 活动地点
        pageNo: 1,
        pageSize: 10,
        adCompany: '',//待制作广告公司
        checkStatus: '',
        creator: '',
        Status: '',
        activityCode: '',// 活动编码
        status:'',
      };
      this.statusName1 = ''
      this.marketCenterName = ""
      this.statusName = ''
      this.tablelist();
    },

    // 分页
    pagesiezes(data, pageSize) {
      this.ssearchList.pageNo = data;
      this.ssearchList.pageSize = pageSize;
      this.tablelist();
    },

    searchClick() {
      this.tablelist();
    },

    /**
     * 活动属性
      */
    getActivityAttributeOptions() {
      let data = {}
      getActivityAttributeOptions(data).then(res => {
        this.activityAttributeOptions = res.data
      })
    },

    // 更改
    handleChangeActivityAttribute(value, e) {
      this.activityAttributeText = value?value:'';
      this.ssearchList.activityAttribute = e?e.key:'';
    },

  }
};
